.phone {
  display: inline-block;

  font-weight: 700;
  text-align: right;
  color: inherit;

  &:hover {
    color: #51a9f7;
    text-decoration: 2px;

    outline: none;

    text-decoration-color: inherit;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-offset: 10px;
  }

  &:active {
    opacity: 0.6;
  }

  &:focus-visible {
    color: #51a9f7;
    text-decoration: 2px;

    outline: none;

    text-decoration-color: inherit;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-offset: 10px;
  }

  @supports not selector(:focus-visible) {
    &:focus {
      color: #51a9f7;
      text-decoration: 2px;

      outline: none;

      text-decoration-color: inherit;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-offset: 10px;
    }
  }
}
