.section {
  position: relative;

  display: flex;
  align-items: center;
  min-height: 450px;
  padding: 100px 0;

  @media (max-width: 1200px) {
    min-height: auto;
    padding: 0;
  }

  & h2 {
    text-align: center;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &::after {
    background-repeat: no-repeat;
    background-size: cover;
  }

  &::before {
    z-index: 1;

    background-color: rgba(17, 22, 42, 0.6);
  }
}

.section:nth-of-type(2n) {
  &::after,
  &::before {
    right: 50%;
    left: 0;
  }
}
