.nav {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;

  @media (max-width: 500px) {
    padding: 20px;
  }
}

.nav__logo {
  display: grid;
  grid-template-columns: 70px 100px;
  gap: 10px;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, auto);
    width: 70px;
  }
}

.nav__logo span {
  @media (max-width: 500px) {
    display: none;
  }
}

.nav__logo svg {
  width: 100%;
  height: auto;
}
