.btn {
  display: inline-block;
  width: 220px;
  padding: 25px 20px;

  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  font-family: $ff-gotham-pro;
  text-align: center;
  color: $color-default-white;

  background: $color-gradient-main;
  border: none;
  border-radius: $border-radius-main;
  cursor: pointer;

  transition: opacity $trans-default;

  @include hover-focus {
    opacity: 0.8;
  }

  &--red {
    background-color: $color-torch-red;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }
}
