.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;

  font-size: 25px;
  line-height: 40px;

  @media (max-width: 768px) {
    display: block;
  }
}

.content__title {
  padding: 40px;
}

.content__text {
  position: relative;
  z-index: 5;

  display: flex;
  justify-content: center;
  padding: 40px;
  // align-items: center;

  color: #ffffff;

  &::after,
  &::before {
    @media (max-width: 768px) {
      content: "";
      position: absolute;

      inset: 0;
    }
  }

  &::after {
    @media (max-width: 768px) {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  &::before {
    @media (max-width: 768px) {
      z-index: 1;

      background-color: rgba(17, 22, 42, 0.6);
    }
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

.section:nth-of-type(2n) .content__text {
  @media (min-width: 769px) {
    order: -1;
  }
}
