.more {
  &::after {
    background-image: url("../../img/more.jpg");
  }
}

.more__content {
  flex-direction: column;

  &::after {
    @media (max-width: 768px) {
      background-image: url("../../img/more.jpg");
    }
  }
}

.more h2 {
  text-align: center;
}
