.footer {
  padding: 30px 40px;

  font-weight: 700;
  color: #ffffff;

  background-color: #11162a;
}

.footer__container {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 500px));
  gap: 30px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
}

.footer address {
  font-style: normal;
}
