.about {
  &::after {
    background-image: url("../../img/about.jpg");
  }
}

.about__content {
  &::after {
    @media (max-width: 768px) {
      background-image: url("../../img/about.jpg");
    }
  }
}
