.adv {
  position: relative;

  font-size: 30px;
  line-height: 40px;

  &::after {
    background-image: url("../../img/adv.jpg");
  }
}

.adv__content {
  color: #ffffff;

  &::after {
    @media (max-width: 768px) {
      background-image: url("../../img/adv.jpg");
    }
  }
}

.adv__list {
  list-style: circle;
}
